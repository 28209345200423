import $ from 'jquery';
import GaTracking from './ga-tracking';
import { createFocusTrap } from 'focus-trap';
export default class Modals {
    static modalTrap;
    static init() {
        this.geoIpModal();
        this.openModalOnLoad();
        this.successModal();
        this.trackModalsFlow();
    }
    static geoIpModal() {
        const $geoIpModal = $('.js__geo-ip-modal');
        if ($geoIpModal.length) {
            GaTracking.pushDataLayer({
                event: 'geofenceOverlay',
            });
        }
    }
    static successModal() {
        const $successModal = $('.js__action-success-modal');
        $successModal.each((index, item) => {
            if ($(item).data('show-modal') === 'show') {
                $(item).modal('show');
            }
        });
    }
    static openModalOnLoad() {
        $('.js__modal-open-on-load').modal('show');
    }
    static trackModalsFlow($root = $('body')) {
        $root.find('[data-toggle="modal"]').on('click', e => {
            const $button = $(e.currentTarget);
            $button.addClass('last-modal-open-click');
        });
        $('.modal').on('show.bs.modal', e => {
            const $modal = $(e.currentTarget);
            const $visibleModal = $('.modal.show');
            if ($visibleModal.length > 0) {
                this.modalTrap.deactivate();
            }
            this.modalTrap = this.createTrapFocus($modal, 'modal');
        });
        $('.modal').on('shown.bs.modal', e => {
            const $modal = $(e.currentTarget);
            this.modalTrap.activate();
            const $lastClickedModalButton = $modal.find('.last-modal-open-click');
            $lastClickedModalButton.trigger('focus');
            $lastClickedModalButton.removeClass('last-modal-open-click');
        });
        $('.modal').on('hide.bs.modal', e => {
            this.modalTrap.deactivate();
        });
        $('.modal').on('hidden.bs.modal', e => {
            setTimeout(() => {
                const $visibleModal = $('.modal.show');
                if ($visibleModal.length !== 1) {
                    return;
                }
                const modalFocusTrap = this.createTrapFocus($visibleModal, '.last-modal-open-click');
                modalFocusTrap.activate();
                $visibleModal.on('hide.bs.modal', () => {
                    modalFocusTrap.deactivate();
                });
                $visibleModal.find('[data-toggle="modal"]').on('click', e => {
                    modalFocusTrap.deactivate();
                });
            }, 500);
        });
    }
    static createTrapFocus($modal, initialFocus = null) {
        return createFocusTrap($modal[0], initialFocus
            ? {
                initialFocus,
            }
            : {});
    }
}
